<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>垫付申请信息</span>
          <span v-if="showHeaderButton">
            <span v-if="$hasPermission('miaoins')">
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-if="header_button"
                @click="editorApplicationInformation()"
                >编辑</el-button
              >
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-else
                @click="saveApplicationInformation()"
                >保存</el-button
              >
            </span>
          </span>
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :disabled="disabled"
            ref="advanceApplicationInformation"
            :rules="dataRule"
            :model="advanceApplicationInformation"
            class="demo-form-inline"
          >
            <el-form-item label="入住时间" prop="applyHospitalDate">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="advanceApplicationInformation.applyHospitalDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions">
              <!-- <el-input
                v-model="advanceApplicationInformation.region"
                placeholder="地区"
              ></el-input> -->
              <el-cascader
                v-model="advanceApplicationInformation.selectedOptions"
                :options="options"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="入住医院">
              <el-input
                v-model="advanceApplicationInformation.applyHospital"
                placeholder="入住医院"
              ></el-input>
            </el-form-item>
            <el-form-item label="入住科室">
              <el-input
                v-model="advanceApplicationInformation.applyDepartment"
                placeholder="入住科室"
              ></el-input>
            </el-form-item>
            <el-form-item label="病情名称">
              <el-input
                v-model="advanceApplicationInformation.applyIllness"
                placeholder="病情名称"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="申请类型">
                    <el-select v-model="advanceApplicationInformation.applyAdvanceType" filterable clearable  placeholder="就诊医院">
                      <el-option
                        v-for="item in applyAdvanceTypeData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item> -->
            <el-form-item label="申请金额">
              <el-input
                v-model="advanceApplicationInformation.applyAdvanceMoney"
                placeholder="申请金额"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="同意垫付金额">
                    <el-input v-model="advanceApplicationInformation.passPayMoney" placeholder="同意垫付金额"></el-input>
                  </el-form-item> -->
            <el-form-item label="收款人姓名">
              <el-input
                v-model="advanceApplicationInformation.payName"
                placeholder="收款人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户银行">
              <el-input
                v-model="advanceApplicationInformation.payBank"
                placeholder="开户银行"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行账户">
              <el-input
                v-model="advanceApplicationInformation.payBankCard"
                placeholder="银行账户"
              ></el-input>
            </el-form-item>
            <el-form-item label="当前垫付状态">
              <el-select
                disabled
                v-model="advanceApplicationInformation.advanceStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in advanceStatusOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="备注">
                    <el-input v-model="advanceApplicationInformation.user" placeholder="备注"></el-input>
                  </el-form-item> -->
            <!-- <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                  </el-form-item> -->
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { regionData } from 'element-china-area-data';
import debounce from 'lodash/debounce';
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      applyHospitalData: [],
      applyAdvanceTypeData: [
        { id: '1', name: '住院垫付' },
        { id: '2', name: '出院垫付' },
      ],
      advanceStatusOptions: [
        { id: 0, name: '初审中' },
        { id: 1, name: '初审不通过' },
        { id: 2, name: '初审通过' },
        { id: 3, name: '垫付审批不通过' },
        { id: 4, name: '审批通过待打款' },
        { id: 5, name: '已垫付' },
        { id: 6, name: '待退款' },
        { id: 7, name: '待补款' },
        { id: 8, name: '结案' },
        { id: 9, name: '已取消' },
        { id: 10, name: '受理中' },
      ],
      advanceApplicationInformation: {
        id: '',
        applyHospitalDate: '',
        applyHospital: '',
        applyDepartment: '',
        applyIllness: '',
        // applyAdvanceType: '',
        applyAdvanceMoney: '',
        passPayMoney: '',
        payName: '',
        payBank: '',
        payBankCard: '',
        advanceStatus: '',
        ticketId: '',
        selectedOptions: [],
      },
      ticketId: '',
      options: [],
      selectedOptions: [],
    };
  },
  components: {
    // AddOrUpdate
  },
  computed: {
    dataRule() {
      return {
        applyHospitalDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  // activated () {
  //   this.getAdvanceApplicationInformations()
  // },
  // created () {
  //   this.getAdvanceApplicationInformations()
  // },
  methods: {
    advanceFun(params) {
      this.advanceApplicationInformation = {
        ...this.advanceApplicationInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      let regionData = [];
      regionData.push(params.entityDetails.province);
      regionData.push(params.entityDetails.city);
      regionData.push(params.entityDetails.district);
      this.advanceApplicationInformation.selectedOptions = regionData;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.$store.commit('entityId', params.entityDetails.id);
      this.getAreaList()
    },
       getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2
        })
        .catch(() => {});
    },
    
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit('fatherMethod');
    },
    saveApplicationInformation() {
      this.$refs['advanceApplicationInformation'].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.advanceApplicationInformation.ticketId = Number(this.ticketId);
        this.$http
          .post(
            `/healthbusiness/health-advance/update`,
            this.advanceApplicationInformation
          )
          .then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg);
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false;
                this.$emit('refreshDataList');
                this.header_button = true;
                this.disabled = true;
                // this.getAdvanceApplicationInformations();
              },
            });
          })
          .catch(() => {});
      });
    },
    getapplyHospitalData() {
      this.$http
        .get(`/information/micsinformationhospital/page`, {
          params: { limit: 99999999 },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.applyHospitalData = res.data.list;
        })
        .catch(() => {});
    },
    handleChange(e) {
      console.log(e)
      this.advanceApplicationInformation.province = e[0];
      this.advanceApplicationInformation.city = e[1];
      this.advanceApplicationInformation.district = e[2];
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
