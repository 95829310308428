import { render, staticRenderFns } from "./advance_basic_information.vue?vue&type=template&id=778c7bdf&scoped=true"
import script from "./advance_basic_information.vue?vue&type=script&lang=js"
export * from "./advance_basic_information.vue?vue&type=script&lang=js"
import style0 from "./advance_basic_information.vue?vue&type=style&index=0&id=778c7bdf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778c7bdf",
  null
  
)

export default component.exports