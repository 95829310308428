<template>
  <div class="mod-green_application_information}">
    <div>
      <el-card>
        <div slot="header" class="clearfix">
          <span>垫付影像件</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="uploadImageContainer">
          <div class="uploadImageContainer_left">
            <div>
              <h3>纸质/电子保单</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="insuranceImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successinsuranceImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>住院证照片：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="InHospitalImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successInHospitalImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>押金单照片：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="depositList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successdepositList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>有效身份证：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="IdCardImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successIdCardImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>门急诊病历：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="outpatientImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successoutpatientImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
          <div class="vertical_border"></div>
          <div class="uploadImageContainer_right">
            <div>
              <h3>检查检验报告：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="inspectionReportList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successinspectionReportList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div>
              <h3>医保卡/就诊卡：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="MedicalInsuranceCardImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successMedicalInsuranceCardImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div>
              <h3>二级以上医院诊断证明：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="secondaryHospitalImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successsecondaryHospitalImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>缴费通知：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="PayCostImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successPayCostImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
            <div class="inspection_report">
              <h3>其他材料：</h3>
              <div>
                <el-upload
                  :action="url"
                  list-type="picture-card"
                  multiple
                  :disabled="disabled"
                  :file-list="OtherImgList"
                  :before-upload="beforeUploadHandle"
                  :on-success="successOtherImgList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      num: 0,
      // 被保险人影像
      // insuredImgList: [], // 被保险人身份图片回显列表
      // CasesImgList: [], // 入院通知单和诊断证明
      // listingImgList: [], // 入院通知单和诊断证明
      // otherImgList: [], // 被保险人身份图片回显列表
      insuranceImgList: [],
      InHospitalImgList: [],
      depositList: [],
      IdCardImgList: [],
      outpatientImgList: [],
      inspectionReportList: [],
      MedicalInsuranceCardImgList: [],
      secondaryHospitalImgList: [],
      PayCostImgList: [],
      OtherImgList: [],
      dialogVisibleInsured: false, // 大图显示
      dialogInsuredImageUrl: '', // 大图显示URL
      imgDataObj: {},
      dialogImageUrl: '',
      dialogVisible: false,
      status: '',
      disabled: false,
    };
  },
  components: {
    // AddOrUpdate
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.url = `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`;
      this.num = 0;
    },
    advanceFun(params) {
      // console.log('sdfs', params);
      let fileList = params.entityDetails.healthBusinessFileList;
      let insuranceImgList = [];
      let InHospitalImgList = [];
      let depositList = [];
      let IdCardImgList = [];
      let outpatientImgList = [];
      let inspectionReportList = [];
      let MedicalInsuranceCardImgList = [];
      let secondaryHospitalImgList = [];
      let PayCostImgList = [];
      let OtherImgList = [];
      for (let i in fileList) {
        let imageObj = {
          uid: fileList[i].id,
          url: fileList[i].fileUrl,
          greenAdvanceId: fileList[i].greenAdvanceId,
          fileName: fileList[i].fileName,
          fileType: fileList[i].fileType,
          fileUrl: fileList[i].fileUrl,
          type: fileList[i].type,
        };
        if (fileList[i].fileType === 6) {
          insuranceImgList.push(imageObj);
        } else if (fileList[i].fileType === 7) {
          InHospitalImgList.push(imageObj);
        } else if (fileList[i].fileType === 8) {
          depositList.push(imageObj);
        } else if (fileList[i].fileType === 9) {
          IdCardImgList.push(imageObj);
        } else if (fileList[i].fileType === 10) {
          outpatientImgList.push(imageObj);
        } else if (fileList[i].fileType === 11) {
          inspectionReportList.push(imageObj);
        } else if (fileList[i].fileType === 12) {
          MedicalInsuranceCardImgList.push(imageObj);
        } else if (fileList[i].fileType === 13) {
          secondaryHospitalImgList.push(imageObj);
        } else if (fileList[i].fileType === 14) {
          PayCostImgList.push(imageObj);
        } else if (fileList[i].fileType === 18) {
          OtherImgList.push(imageObj);
        }
      }
      this.insuranceImgList = insuranceImgList;
      this.InHospitalImgList = InHospitalImgList;
      this.depositList = depositList;
      this.IdCardImgList = IdCardImgList;
      this.outpatientImgList = outpatientImgList;
      this.inspectionReportList = inspectionReportList;
      this.MedicalInsuranceCardImgList = MedicalInsuranceCardImgList;
      this.secondaryHospitalImgList = secondaryHospitalImgList;
      this.PayCostImgList = PayCostImgList;
      this.OtherImgList = OtherImgList;
      this.status = params.status;
      // if (res.data.status === 2) {
      //   this.disabled = true
      // }
      if (params.status === 2 || params.status === 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // getinsuredImgList() {
    //   let params = {
    //     id: this.$route.query.id,
    //   };
    //   // this.insuredImgList = [];
    //   // this.CasesImgList = [];
    //   // this.listingImgList = [];
    //   // this.otherImgList = [];
    //   this.$http
    //     .post(`/healthbusiness/health-tickets/getTicketsDetails`, params)
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       let fileList = res.data.entityDetails.healthBusinessFileList;
    //       for (let i in fileList) {
    //         let imageObj = {
    //           uid: fileList[i].id,
    //           url: fileList[i].fileUrl,
    //           greenAdvanceId: fileList[i].greenAdvanceId,
    //           fileName: fileList[i].fileName,
    //           fileType: fileList[i].fileType,
    //           fileUrl: fileList[i].fileUrl,
    //           type: fileList[i].type,
    //         };
    //         if (fileList[i].fileType === 1) {
    //           this.insuredImgList.push(imageObj);
    //         } else if (fileList[i].fileType === 2) {
    //           this.CasesImgList.push(imageObj);
    //         } else if (fileList[i].fileType === 3) {
    //           this.listingImgList.push(imageObj);
    //         } else if (fileList[i].fileType === 4) {
    //           this.otherImgList.push(imageObj);
    //         }
    //       }
    //       this.status = res.data.status;
    //       // if (res.data.status === 2) {
    //       //   this.disabled = true
    //       // }
    //       if (res.data.status === 2 || res.data.status === 0) {
    //         this.disabled = true;
    //       } else {
    //         this.disabled = false;
    //       }
    //     })
    //     .catch(() => {});
    // },
    insertGreenFile() {
      this.$http
        .post(
          `/healthbusiness/health-business-file/insertFile`,
          this.imgDataObj
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let imageObj = {
            uid: res.data.id,
            url: res.data.fileUrl,
            greenAdvanceId: res.data.greenAdvanceId,
            fileName: res.data.fileName,
            fileType: res.data.fileType,
            fileUrl: res.data.fileUrl,
            type: res.data.type,
          };
          if (res.data.fileType === 6) {
            this.insuranceImgList.push(imageObj);
          } else if (res.data.fileType === 7) {
            this.InHospitalImgList.push(imageObj);
          } else if (res.data.fileType === 8) {
            this.depositList.push(imageObj);
          } else if (res.data.fileType === 9) {
            this.IdCardImgList.push(imageObj);
          } else if (res.data.fileType === 10) {
            this.outpatientImgList.push(imageObj);
          } else if (res.data.fileType === 11) {
            this.inspectionReportList.push(imageObj);
          } else if (res.data.fileType === 12) {
            this.MedicalInsuranceCardImgList.push(imageObj);
          } else if (res.data.fileType === 13) {
            this.secondaryHospitalImgList.push(imageObj);
          } else if (res.data.fileType === 14) {
            this.PayCostImgList.push(imageObj);
          } else if (res.data.fileType === 18) {
            this.OtherImgList.push(imageObj);
          }
          this.$message({
            message: '上传成功',
            type: 'success',
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    // 删除影像件
    handleRemove(file, fileList) {
      if (this.status === 2) {
        return this.$message.error('服务已完成，无法删除！');
      }
      this.$http
        .get(`/healthbusiness/health-business-file/delete`, {
          params: { id: file.uid },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (file.fileType === 6) {
            this.insuranceImgList.splice(
              this.insuranceImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 7) {
            this.InHospitalImgList.splice(
              this.InHospitalImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 8) {
            this.depositList.splice(
              this.depositList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 9) {
            this.IdCardImgList.splice(
              this.IdCardImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 10) {
            this.outpatientImgList.splice(
              this.outpatientImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 11) {
            this.inspectionReportList.splice(
              this.inspectionReportList.findIndex(
                (item) => item.id === file.uid
              ),
              1
            );
          } else if (file.fileType === 12) {
            this.MedicalInsuranceCardImgList.splice(
              this.MedicalInsuranceCardImgList.findIndex(
                (item) => item.id === file.uid
              ),
              1
            );
          } else if (file.fileType === 13) {
            this.secondaryHospitalImgList.splice(
              this.secondaryHospitalImgList.findIndex(
                (item) => item.id === file.uid
              ),
              1
            );
          } else if (file.fileType === 14) {
            this.PayCostImgList.splice(
              this.PayCostImgList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 18) {
            this.OtherImgList.splice(
              this.OtherImgList.findIndex((item) => item.id === file.uid),
              1
            );
          }
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 500,
          });
        })
        .catch(() => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传之前
    beforeUploadHandle(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (
        ['jpg', 'jpeg', 'png', 'gif', 'pdf'].indexOf(FileExt.toLowerCase()) ===
        -1
      ) {
        this.$message({
          type: 'warning',
          // message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件！'
          message: '请上传后缀名为jpg、png、pdf的附件！',
        });
        return false;
      }
      this.num++;
    },
    // >纸质/电子保单
    successinsuranceImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 6;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 住院证照片：
    successInHospitalImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 7;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 押金单照片：
    successdepositList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 8;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 有效身份证：
    successIdCardImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 9;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 门急诊病历：
    successoutpatientImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 10;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 检查检验报告：
    successinspectionReportList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 11;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 医保卡/就诊卡
    successMedicalInsuranceCardImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 12;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 二级以上医院诊断证明：
    successsecondaryHospitalImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 13;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 缴费通知：
    successPayCostImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 14;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 缴费通知：
    successOtherImgList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 18;
      data.type = 2;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.uploadImageContainer {
  display: flex;
  margin-bottom: 20px;
}
.uploadImageContainer_left {
  flex: 50%;
}
.uploadImageContainer_right {
  flex: 50%;
}
.vertical_border {
  border-left: 1px solid #ccc;
}
.inspection_report {
  margin-top: 30px;
}
.uploadImageContainer_right > div {
  margin-left: 30px;
}
h3 {
  color: rgb(114, 113, 113);
}
</style>
