<template>
  <div class="mod-green_basic_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>垫付申请审批结果</span>
          <span v-show="showHeaderButton">
            <span v-if="$hasPermission('miaoins')">
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-if="header_button"
                @click="editorAdvanceBasicInformation()"
                >编辑</el-button
              >
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-else
                @click="saveAdvanceBasicInformation()"
                >保存</el-button
              >
            </span>
          </span>
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :disabled="disabled"
            :model="advanceBasicInformation"
            class="demo-form-inline"
          >
            <el-form-item label="垫付金额">
              <el-input
                v-model="advanceBasicInformation.passPayMoney"
                clearable
                placeholder="用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="垫付渠道">
              <el-input
                v-model="advanceBasicInformation.channelAppid"
                clearable
                placeholder="垫付渠道"
              ></el-input>
            </el-form-item>
            <el-form-item label="TPA代表">
              <el-input
                v-model="advanceBasicInformation.settleTpa"
                clearable
                placeholder="TPA代表"
              ></el-input>
            </el-form-item>
            <el-form-item label="不予通过原因">
              <el-input
                v-model="advanceBasicInformation.notPassReason"
                clearable
                placeholder="不予通过原因"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="advanceBasicInformation.note"
                clearable
                placeholder="备注"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                  </el-form-item> -->
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      advanceBasicInformation: {
        id: '',
        passPayMoney: '',
        channelAppid: '',
        settleTpa: '',
        idCard: '',
        note: '',
        ticketId: '',
      },
      ticketId: '',
    };
  },
  components: {
    // AddOrUpdate
  },
  // activated () {
  //   this.getAdvanceBasicInformation()
  // },
  // mounted() {
  //   this.getAdvanceBasicInformation();
  // },
  methods: {
    advanceFun(params) {
      this.advanceBasicInformation = {
        ...this.advanceBasicInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;

      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.$store.commit('entityId', params.entityDetails.id);
    },
    // getAdvanceBasicInformation() {
    //   let params = {
    //     id: this.$route.query.id,
    //   };
    //   this.$http
    //     .post(`/healthbusiness/health-tickets/getTicketsDetails`, params)
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       this.advanceBasicInformation = {
    //         ...this.advanceBasicInformation,
    //         ...res.data.entityDetails,
    //       };
    //       if (res.data.status === 2 || res.data.status === 0) {
    //         this.showHeaderButton = false;
    //       } else {
    //         this.showHeaderButton = true;
    //       }
    //       // this.advanceBasicInformation.channelResultStatus = res.data.entityDetails.advanceStatus
    //     })
    //     .catch(() => {});
    // },
    editorAdvanceBasicInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit('fatherMethod');
    },
    saveAdvanceBasicInformation() {
      this.advanceBasicInformation.ticketId = Number(this.ticketId);
      this.$http
        .post(
          `/healthbusiness/health-advance/update`,
          this.advanceBasicInformation
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false;
              this.header_button = true;
              this.disabled = true;
              // this.getAdvanceBasicInformation();
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
