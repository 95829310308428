<template>
  <div class="mod-green_basic_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>垫付打款结果</span>
          <span v-if="showHeaderButton">
            <span v-if="$hasPermission('miaoins')">
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-if="header_button"
                @click="editorAdvanceBasicInformation()"
                >编辑</el-button
              >
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                v-else
                @click="saveAdvanceBasicInformation()"
                >保存</el-button
              >
            </span>
          </span>
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :disabled="disabled"
            :model="advanceBasicInformation"
            class="demo-form-inline"
          >
            <el-form-item label="打款金额">
              <el-input
                v-model="advanceBasicInformation.channelPayMoney"
                clearable
                placeholder="打款金额"
              ></el-input>
            </el-form-item>
            <el-form-item label="打款时间">
              <!-- <el-input v-model="advanceBasicInformation.channelPayDate" clearable placeholder="打款时间"></el-input> -->
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="advanceBasicInformation.channelPayDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="打款状态">
              <el-select disabled v-model="advanceBasicInformation.channelResultStatus" placeholder="打款状态">
                <el-option
                  v-for="item in ResultStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHeaderButton: true,
      header_button: true,
      disabled: true,
      advanceBasicInformation: {
        id: '',
        channelPayMoney: '',
        channelPayDate: '',
        channelResultStatus: '',
        ticketId: '',
      },
      ResultStatus: [
        { id: 5, name: '已垫付' },
        { id: 6, name: '待退款' },
        { id: 7, name: '待补款' },
      ],
      ticketId: '',
    };
  },
  components: {
    // AddOrUpdate
  },
  // created() {
  //   this.getAdvanceBasicInformation();
  // },
  // mounted () {
  //   this.getAdvanceBasicInformation();
  // },
  methods: {
    advanceFun(params) {
      // console.log('sdfs', params);
      this.advanceBasicInformation = {
        ...this.advanceBasicInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      this.advanceBasicInformation.id = params.entityDetails.id;
      this.advanceBasicInformation.channelResultStatus =
        params.entityDetails.advanceStatus;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
    },
    // getAdvanceBasicInformation() {
    //   let params = {
    //     id: this.$route.query.id,
    //   };
    //   this.$http
    //     .post(`/healthbusiness/health-tickets/getTicketsDetails`, params)
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       this.advanceBasicInformation = {
    //         ...this.advanceBasicInformation,
    //         ...res.data.entityDetails,
    //       };
    //       this.advanceBasicInformation.id = res.data.entityDetails.id;
    //       this.advanceBasicInformation.channelResultStatus =
    //         res.data.entityDetails.advanceStatus;
    //       if (res.data.status === 2 || res.data.status === 0) {
    //         this.showHeaderButton = false;
    //       } else {
    //         this.showHeaderButton = true;
    //       }
    //     })
    //     .catch(() => {});
    // },
    editorAdvanceBasicInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit('fatherMethod');
    },
    saveAdvanceBasicInformation() {
      this.advanceBasicInformation.ticketId = Number(this.ticketId);
      this.$http
        .post(
          `/healthbusiness/health-advance/update`,
          this.advanceBasicInformation
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false;
              this.header_button = true;
              this.disabled = true;
              // this.getAdvanceBasicInformation();
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
